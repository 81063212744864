import { BrowserModule } from '@angular/platform-browser';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule, PLATFORM_ID, APP_ID, Inject } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import {CommonModule, isPlatformBrowser} from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppComponent } from './app.component';
import { AppComponentService } from './app.component.service';

import {
          AuthService,
          LoginComponent,
          CallbackComponent,
          TosComponent  } from './auth-module/auth-module.module';


import { LayoutModule } from './layout/layout.module';
import { ContentCardModule } from './content-card/content-card.module';
import { ContentContainerComponent } from './content-container/content-container.component';

import { ApiMiddlewareService, ApiMiddlewareModule } from './api-middleware/api-middleware.module';
import {AutoLogoutService} from './auto-logout.service';
import {DriverLogsModule} from './driver-logs/driver-logs.module';
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import {CertifyLogsModule} from './certify-logs/certify-logs.module';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../environments/environment';
import * as data from '../environments/auth-urls.json';
import {ZonarUiIconModule} from '@zonar-ui/icon';
import {ZonarUIMaterialModule} from '@zonar-ui/material';
import { NgxPendoModule } from 'ngx-pendo';
import { SysvarReducer } from './state/app.reducer';
import { SysvarEffects } from './state/app.effects';
import { ZonarUiHosComponentsModule } from '@zonar-ui/hos-components';
import { ZonarUICoreModule } from '@zonar-ui/core';
import {AuthGuard, AuthHttpInterceptor, AuthModule} from '@auth0/auth0-angular';
import {UserInfoService} from './user-info.service';

const appRoutes: Routes = [
  { path: 'dashboard', component: ContentContainerComponent },
  { path: '', component: ContentContainerComponent, canActivate: [AuthGuard] },
  { path: 'callback', component: CallbackComponent },
  { path: 'login', component: LoginComponent},
  { path: 'tos', component: TosComponent}
];

@NgModule({
  declarations: [
    AppComponent,
    ContentContainerComponent,
  ],
  exports: [
  ],
  imports: [
    RouterModule.forRoot(appRoutes, {
        initialNavigation: 'enabledBlocking'
    }),
    FormsModule,
    BrowserModule.withServerTransition({appId: 'driver-portal'}),
    HttpClientModule,
    ContentCardModule,
    LayoutModule,
    ApiMiddlewareModule.forRoot(),
    AuthModule.forRoot({
      domain: environment.auth.domain,
      clientId: environment.auth.clientID,
      authorizationParams: {
        audience: environment.auth.audience,
        redirect_uri: window.location.origin + '/callback'
      },
      httpInterceptor: {
        allowedList: data
      }
    }),
    BrowserAnimationsModule,
    DriverLogsModule,
    StoreModule.forRoot({}),
    CommonModule,
    EffectsModule.forRoot([]),
    CertifyLogsModule,
    StoreDevtoolsModule.instrument({
        maxAge: 25, // Retains last 25 states
        logOnly: environment.production, // Restrict extension to log-only mode
    }),
    NgxPendoModule.forRoot({
      pendoApiKey: '47e63a49-4477-47c2-4ea6-0f032e1785d5',
      pendoIdFormatter: (value: any) => value.toString().toLowerCase()
    }),
    ZonarUiIconModule,
    ZonarUIMaterialModule,
    StoreModule.forFeature('sysvars', SysvarReducer),
    EffectsModule.forFeature([SysvarEffects]),
    ZonarUiHosComponentsModule,
    ZonarUICoreModule
  ],

  providers: [
    AuthGuard,
    AppComponentService,
    ApiMiddlewareService,
    AuthService,
    AutoLogoutService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthHttpInterceptor,
      multi: true
    },
    UserInfoService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    @Inject(APP_ID) private appId: string) {
    const platform = isPlatformBrowser(platformId) ?
      'in the browser' : 'on the server';
    console.log(`Running ${platform} with appId=${appId}`);
  }
}
