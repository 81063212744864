import {Component, OnInit, OnChanges, OnDestroy} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';

import { ContentContainerUtils} from './content-container.utils';
import {AppComponentService} from '../app.component.service';
import {AutoLogoutService} from '../auto-logout.service';
import {ZONAR_TIME_FORMAT} from '../driver-logs/pipe/zonar-timestamp.pipe';
import {Store} from '@ngrx/store';
import {DriverModuleState} from '../driver-logs/state/reducers/driver-logs.reducer';
import {SetDate} from '../driver-logs/state/actions/driver-logs.actions';
import {GetDriverEvents} from '../driver-logs/state/actions/driver-events.actions';
import {GetDriverGridData} from '../driver-logs/state/actions/driver-grid-data.actions';
import {GetDriverClocks} from '../driver-logs/state/actions/driver-clocks.actions';
import {CertifyLogsState} from '../certify-logs/state/certify-logs.reducer';
import {GetUncertifiedDays} from '../certify-logs/state/certify-logs.actions';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import {BehaviorSubject, interval, Subscription} from 'rxjs';
import {UserInfoService} from '../user-info.service';
import {ApiMiddlewareService} from '../api-middleware/api-middleware.service';

dayjs.extend(timezone);

@Component({
    selector: 'app-content-container',
    templateUrl: './content-container.component.html',
    styleUrls: ['./content-container.component.scss'],
})
export class ContentContainerComponent implements OnInit, OnDestroy {
    // internal var for data
    contentArray: any;
    data: any;
    // internal var needed for state management
    active: boolean;
    obs: any;
    // util service
    utils = new ContentContainerUtils;
    // selected profile
    SelectedDutyStatus: any;
    SelectedDriverProfile: any;
    SelectedDivisionName: any;
    SelectedCompanyName: any;
    SelectedCompany: any;
    ActiveEditCompany: any;
    ActiveEditDivision: any;
    divisions: any[];
    profiles: any[];
    type: string;
    val: any;

    isInCertifyMode: any = false;
    isDriverWithNoDutyStatus = false;
    displayText: BehaviorSubject<string> = new BehaviorSubject<string>('');

    //everything below this is new code

    driverInfo = {
      userName: '',
      company: '',
      userId: '',
      divisions: [],
      currentDivision: '',
      authStatus: false,
    };

    constructor(
        private snackBar: MatSnackBar,
        // private appService: AppComponentService,
        private autoLogout: AutoLogoutService,
        private driverLogsStore: Store<DriverModuleState>,
        private certifyLogsStore: Store<CertifyLogsState>,
        private userInfo: UserInfoService,
        private apiService: ApiMiddlewareService,) {
      // console.log('in the content component')
      // appService.loadData();
    }

    ngOnInit() {
      this.driverInfo = this.userInfo.driverInformation;
      console.log('driver info in content', this.driverInfo);
        // create blank instance so content loads
        this.contentArray = this.utils.generateContentModel('Loading....', 'loading');
        this.data = {};
        if (this.driverInfo.authStatus && this.driverInfo.userId) {
          //todo set up state for driver info and get selectors
          this.obs = this.apiService.getDutyStatus(this.driverInfo.userId).then((val) => {
            setInterval(e => {
              if (!this.active) {
                if (!this.isDriverWithNoDutyStatus) {
                  console.log('getting duty status?')
                  this.mapData(val);
                }
                // this.profiles = this.appService.getProfilesList();
                // this.divisions = this.appService.getUserDivisionsList();
              }
            }, 10000);
          })
            // this.obs = this.appService.getDataObservable.subscribe(val => {
            //
            // });
        }
        // AppComponentService.driverWithNoDS.subscribe(val => {
        //   this.isDriverWithNoDutyStatus = val;
        //   if (val) {
        //     this.displayText.next(`Tablet login needed for driver dashboard data. Please log in now.`);
        //   }
        // })
    }

    async isAuthenticated() {
        // return await this.appService.checkIfAuth();
    }

    onClick(objct: any) {
        // const indexValue = Number(objct.key);
        // let profile = this.contentArray[indexValue];
        // this.active = true;
        // profile = this.utils.returnState(profile, objct.value);
        // if (profile.mediaContent && profile.mediaContent.state === 'loading') {
        //     this.appService.setDutyStatus(this.ActiveEditDivision.id, objct.value, this.type).then((d: any) => {
        //         if (!d.error) {
        //             this.snackBar.open('Saved', '', {duration: 2000, });
        //             this.updateDutyStatusValue(d, this.getCompany(this.ActiveEditDivision.companyId));
        //             if (this.SelectedDriverProfile === this.ActiveEditDivision) {
        //                 setTimeout(e => this.active = false, 30000);
        //             } else {
        //                 this.active = false;
        //             }
        //             this.ActiveEditDivision = null;
        //             setTimeout(() => {
        //                 if (!this.isInCertifyMode) {
        //                     this.driverLogsStore.dispatch(new SetDate(dayjs().format(ZONAR_TIME_FORMAT.yearMonthDay)));
        //                     this.driverLogsStore.dispatch(new GetDriverEvents());
        //                     this.driverLogsStore.dispatch(new GetDriverGridData());
        //                     this.driverLogsStore.dispatch(new GetDriverClocks());
        //                     this.certifyLogsStore.dispatch(new GetUncertifiedDays());
        //                 }
        //             }, 900);
        //         } else {
        //             this.snackBar.open('Save Failed. Try Reloading the Page', '', {duration: 1000, });
        //         }
        //     });
        // }
        // if (profile === 'CANCEL') {
        //     setTimeout(e => this.active = false, 400);
        //     this.ActiveEditDivision = null;
        // }
        // if (profile === 'clicked') {
        //     this.ActiveEditCompany = this.SelectedCompany;
        //     this.ActiveEditDivision = this.SelectedDriverProfile;
        // }
    }

    headerOnClick(e: any) {
        this.data.driverProfiles.map(x => {
            if (x.divisionId === e) {
                this.SelectedDriverProfile = x;
                if (this.ActiveEditDivision === null) {
                    this.active = false;
                }
            }
        });
    }

    // make sure card doesnt leave open sub on destroy
    ngOnDestroy() {
        if (this.obs) {
            this.obs.unsubscribe();
        }
    }

    getCompany(id) {
        return this.data.divisions.find(x => {
            if (x.id === id) {
                return x;
            }
        });
    }

    // util function that detects if card is active (i.e. user is entering data)
    mapData(val) {
      console.log('in map value', val);
      this.updateDutyStatusValue(val);
        // if (val !== this.data && !this.active) {
        //     this.data = val;
        // }
        // if (this.data.driverProfiles) {
        //     if (!this.SelectedDriverProfile) {
        //         this.SelectedDriverProfile = this.data.driverProfiles[0];
        //     }
        // }
        // if (this.data.driverProfiles && this.data.divisions) {
        //     this.SelectedCompany = this.getCompany(this.SelectedDriverProfile.companyId);
        //     this.data.divisions.find(company => {
        //         if (company.id && company.id === this.SelectedCompany.id) {
        //
        //             company.divisions.find(division => {
        //                 if (division.id === this.SelectedDriverProfile.divisionId) {
        //                     this.SelectedCompanyName = this.SelectedCompany.name;
        //                     this.SelectedDivisionName = division.name;
        //                 }
        //             });
        //         }
        //     });
        // }
        // if (this.data.driverProfiles && this.data.divisions && this.data.dutyStatus) {
        //     this.SelectedDutyStatus = this.data.dutyStatus.find(x => {
        //         if (x.profileId === this.SelectedDriverProfile.id) {
        //             return x;
        //         }
        //     });
        //     if (!this.active) {
        //         if (this.SelectedDutyStatus) {
        //             this.type = this.SelectedDutyStatus.type;
        //         }
        //
        //     }
        // }
    }

    // updates duty status display while aware of special duty status
    updateDutyStatusValue(status) {
      let getHourDelta = (creationTime) => {
        const delta = Math.round(new Date().getTime() - Date.parse(creationTime)) ;
        if (delta > 3600000) {
          return Math.round(delta / 3600000).toString() + ' HR';
        } else {
          return  Math.round(delta / 60000).toString() + ' Min';
        }
      }
        const hourDelta = status ? getHourDelta(status.created) : null;

        if (status && !status.dutyStatus) {
            this.contentArray = this.utils.generateContentModel(
                status.specialDutyStatus,
                status.specialDutyStatus,
                this.driverInfo.company,
                hourDelta,
                'button',
                this.driverInfo.currentDivision
            );
        } else if (status && status.specialDutyStatus) {
            this.contentArray = this.utils.generateContentModel(
                status.specialDutyStatus,
                status.specialDutyStatus,
              this.driverInfo.company,
                hourDelta,
                'status',
              this.driverInfo.currentDivision
            );
        } else if (status) {
            this.contentArray = this.utils.generateContentModel(
                status.dutyStatus,
                status.dutyStatus,
              this.driverInfo.company,
                hourDelta,
                'status',
              this.driverInfo.currentDivision
            );
        }
    }

    hasID(profile: any) {
        return profile;
    }

    toggleCertify(info: any) {
        this.isInCertifyMode = info;
    }
}
