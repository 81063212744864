import {Injectable} from '@angular/core';
import {Observable, of, Subject} from 'rxjs';
import {DriverEvent} from '../model/driver-event';
import {ByDriverProfileService} from '../../api-middleware/api-service/compliance/services/by-driver-profile.service';
import {DriverLogsParams} from './actions/driver-clocks.actions';
import {AddEventParams, EditEventParams} from './actions/driver-events.actions';
import { Utils } from '../../utils';

const PRECISE_TOTAL_MILES_EVENTS = [
    'CERTIFICATION',
    'AUTH_STATUS', // Log in/Log out
    'DIAG_MAL', // Diagnosis, Malfunction
];
const ACCUMULATED_MILES_EVENTS = [
    'DUTY_STATUS',
    'SDS', // covers both start and clear
    'INTERMEDIATE',
];
const TOTAL_MILES_EVENTS = [
    'CERTIFICATION',
    'AUTH_STATUS', // Log in/Log out
    'DIAG_MAL', // Diagnosis, Malfunction
    'ENGINE_STATUS', // Key on, Key off,
    'SYSTEM_DESCRIPTOR',
    'CANADA_OPERATING_ZONE',
    'DEFERRAL_STATE'
];
const LOCATIONLESS_EVENTS = [
    'ASSET_CHANGE',
    'CERTIFICATION',
    'APPLIED_USER_DESCRIPTOR',
    'METADATA',
    'RULESET_CHANGE',
];

const SDS_PAIRING = {
    'ON_DUTY': ['YM', 'HR', 'CLEAR_HR', 'CLEAR_YM'],
    'OFF_DUTY': ['PC', 'WT', 'CLEAR_PC', 'CLEAR_WT']
};

function elementInList(nail: string, container: any[]) {
    return container.indexOf(nail) !== -1;
}

@Injectable({
    providedIn: 'root'
})
export class DriverLogsService {

    constructor(public dutyStatusService: ByDriverProfileService) {
    }

    getDriverSummary(params: DriverLogsParams): Observable<any> {
        return this.dutyStatusService.getGetDriverEventSummary(params);
    }

    getDriverClocks(params: DriverLogsParams ): Observable<any> {
        return this.dutyStatusService.getGetDriverEventSummary(params);
    }

    getDriverEvents(params: DriverLogsParams ): Observable<any> {
        return this.dutyStatusService.getGetDriverEventList(params);
    }

    driverEventTransform(driverEvents: DriverEvent[]): DriverEvent[] {

        // silently breaks when event.event_code_name is null
        try {
            for (const event of driverEvents) {
                const event_type = event.event_type_name;
                const event_code = event.event_code_name;
                event.single_line = (event_code.includes('CO_DRIVER')
                    || (event_code.includes('CLEAR') && event_type === 'SDS')
                    || (event_type === 'SLEEPER_BERTH_SPLIT')
                    || (event_type !== 'CYCLE_CHANGE' && event_type.includes('CHANGE')));
                event.display_icon = (event_type === 'DUTY_STATUS' || event_type === 'SDS');
                event.display_location = this.showLocation(event);
                event.display_total_miles = this.showTotalMiles(event);
                event.display_accum_miles = this.showAccmMiles(event);
                event.display_comment = this.showComment(event);
                event.display_edit = this.showEditIcon(event);
                // todo check if was_system is needed
                event.was_system = this.eventWasOriginallySystemGenerated(event);
            }
        } catch (error) {
            console.log('Error processing driver events: ');
            console.log(error);
        }

        driverEvents = this.removeDuplicateDSEvents(driverEvents);

        return driverEvents;
    }

    postDriverEditEvent(params: EditEventParams): Observable<any> {
        return this.dutyStatusService.postEditDriverEvent(params);
    }

    postAddDriverEvent(params: AddEventParams): Observable<any> {
        return this.dutyStatusService.postAddDriverEvent(params);
    }

    private removeDuplicateDSEvents(driverEvents: DriverEvent[]): DriverEvent[] {
        const sdsEvents = [];
        driverEvents.forEach((event) => {
            if (event.event_type_name === 'SDS') {
                sdsEvents.push(event);
            }
        });
        sdsEvents.forEach((sds: DriverEvent) => {
            const found = driverEvents.findIndex((e) => {
                if (e.event_type_name === 'DUTY_STATUS' && e.epoch === sds.epoch &&
                    this.sdsMatch(e, sds)) {
                    sds.parent_event = e; // associate the DS and sds event together
                    sds.parent_oid = e.oid;
                    return e;
                }
            });
            if (found > -1) {
                driverEvents.splice(found, 1);
            }
        });
        return driverEvents;
    }

    private showLocation(event: DriverEvent): boolean {
        return !elementInList(event.event_type_name, LOCATIONLESS_EVENTS) && !!event.location;
    }

    private showTotalMiles(event: DriverEvent): boolean {
        const inPreciseTotal = elementInList(event.event_type_name, PRECISE_TOTAL_MILES_EVENTS)
            && !event.reduced_precision;
        const inTotalMile = elementInList(event.event_type_name, TOTAL_MILES_EVENTS);
        const isAdverseWeather = event.event_code_name === 'ADVERSE_WEATHER';
        return inPreciseTotal || inTotalMile || isAdverseWeather;
    }

    private showAccmMiles(event: DriverEvent): boolean {
        return elementInList(event.event_type_name, ACCUMULATED_MILES_EVENTS) && !event.reduced_precision;
    }

    private showComment(event: DriverEvent): boolean {
        const areThereComments = !!event.comments;
        const isUserDescriptor = (event.event_type_name === 'APPLIED_USER_DESCRIPTOR')
            || (event.event_type_name === 'EFFECT_USER_DESCRIPTOR');
        const isAdverseWeather = event.event_code_name === 'ADVERSE_WEATHER';
        return !((isUserDescriptor && !isAdverseWeather) || !areThereComments);
    }

    private showEditIcon(event: DriverEvent): boolean {
        const editableEventTypes = [
            'DUTY_STATUS',
            'SDS',
            'CANADA_OPERATING_ZONE',
            'DEFERRAL_STATE',
            'CYCLE_CHANGE'
        ];

        return Utils.includes(editableEventTypes, event.event_type_name);
    }

    private eventWasOriginallySystemGenerated(event: DriverEvent) {
        let value = false;
        if (event.historical_events && event.historical_events.length > 0) {
            const historicalEvents = event.historical_events;
            historicalEvents.forEach((e) => {
                if (e.event_record_origin_name === 'SYSTEM') {
                    value = true;
                }
            });
        }
        return value;
    }

    private sdsMatch(event, sds) {
        if (SDS_PAIRING[event.event_code_name]) {
            return SDS_PAIRING[event.event_code_name].includes(sds.event_code_name);
        }
        return false;
    }

}
