<div class="date-control">
    <zui-icon icon="chevron_left"
              class="arrows"
              inline="true"
              aria-label="Previous Day"
              (click)="goBack()"
              [ngClass]="{
       'disable-arrow': !canGoBack() || isDisabled,
       'hide-arrow': isInCertifyMode}"
    ></zui-icon>
    <h6 class="date-title">
      {{this.selectedDate | zonarTimestamp: 'full'}}
    </h6>
    <zui-icon icon="chevron_right"
              class="arrows"
              inline="true"
              aria-label="Next Day"
              (click)="goForward()"
              [ngClass]="{
       'disable-arrow': !canGoForward() || isDisabled,
       'hide-arrow': isInCertifyMode
       }"
    ></zui-icon>
</div>
