<div>HELLO WORLD HERE HEADER</div>
<section class="top-header">
    <div class='content-container'>
        <span class="driverlogo">
          <img src="{{logoUrl}}"/>
        </span>
        <span class="fill-space"></span>
        <span class="user-select"
              [ngClass]="{ 'disabled' : !isAuthorized, 'extra-padding-right': !isAuthorized}"
             [class.expanded]="expanded"
             [matMenuTriggerFor]="appMenu"
             (menuOpened)="onMenuOpened()"
             (menuClosed)="onMenuClosed()">
          <div class='name-container material-icons menu'>
              <div class="username">{{userName}}</div>
              <div *ngIf="!userName" class="lod" style='width:30%'></div>
              <div *ngIf="!company" class="lod"></div>
              <div class="company" *ngIf="company">{{selectedDivision}}</div>
          </div>
          <div class="user-icon">
              <i style='color:#fff' class="material-icons menu">account_circle</i>
          </div>
          <div class="menu-container" *ngIf="isAuthorized">
            <mat-icon class="user-chevron-button material-icons menu" [@rotateChevron]="expanded">expand_more</mat-icon>
          </div>
        </span>
    </div>
</section>
<mat-menu  #appMenu="matMenu" yPosition="below" xPosition="before" [overlapTrigger]="false" md-position-mode="target-left target" md-offset="0 42"
  class="user-menu app-menu">
    <div *ngIf="!userName" class="lod" style='width:30%'></div>
    <div class="username-menu">{{userName}}</div>
    <div *ngIf="!company" class="lod"></div>
    <div class="company-menu" *ngIf="company">{{selectedDivision}}</div>
    <button mat-menu-item (click)='this.resetPassword()' class="pwdChange"><img src="/static/img/user.png"/>Change Password</button>
    <button mat-menu-item (click)='this.logOut()' class="logoutChange"><img src="/static/img/logout.png"/>Logout</button>
<!--      TODO: Disabled account switch until we can support it ZLOGS-9239-->
<!--      <app-account-switcher -->
<!--      [profilesArray]='divisions' -->
<!--      (selectedAccount)='onClick($event)'></app-account-switcher>-->
</mat-menu>
