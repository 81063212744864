import { Injectable } from '@angular/core';
import { ApiMiddlewareService } from './api-middleware/api-middleware.service';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import { AppModel } from './app.model';
// import { AuthService } from './auth-module/auth/auth.service';
import { AUTH_ACTIVE_CONFIG } from './auth-module/auth/auth0-variables';
import {Store} from '@ngrx/store';
import {DriverModuleState} from './driver-logs/state/reducers/driver-logs.reducer';
import {SetDriverUuid} from './driver-logs/state/actions/driver-logs.actions';
import {NgxPendoService} from 'ngx-pendo';
import {AuthService} from '@auth0/auth0-angular';

@Injectable()
export class AppComponentService  {
  data = new Observable();
  userData = new AppModel;
  obs: any;
  env: any;
  static driverWithNoDS = new BehaviorSubject<boolean>(false);

  // public getDataObservable = new Observable((observer) => {
  //   const {next, error} = observer;
  //   observer.next(this.userData);
  //   this.obs = setInterval(e => {
  //     // periodically update duty status
  //     this.userData.driverProfiles.map(( x ) => {
  //       this.getDutyStatus(x.id).then(z => {
  //         if (!z.error) {
  //           this.updateStatusValue(x.id, z);
  //         } else {
  //           console.log('failed to load data');
  //         }
  //       });
  //     });
  //     observer.next(this.userData);
  //   }, 90000);
  //   observer.complete();
  // });

  constructor(public apiMiddleWare: ApiMiddlewareService,
              public auth: AuthService,
              private driverLogsStore: Store<DriverModuleState>,
              protected ngxPendoService: NgxPendoService
              ) {
                this.userData.divisions = [];
                this.userData.dutyStatus = [];
                // alert('in app service');
                // this.loadData();
                // this.env = AUTH_ACTIVE_CONFIG(this.auth.getEnvironment()['env']);
              }

  // async loadData() {
  //   this.auth.user$.subscribe(async (profile) => {
  //     let sub = profile.sub;
  //     console.log('in get user auth', profile);
  //     this.userData.driverUserProfile = await this.apiMiddleWare.getUser(sub).then((d: any) => d[0]);
  //     console.log(this.userData.driverUserProfile);
  //     alert('after user get');
  //
  //     // load driver
  //     this.userData.driver = await this.apiMiddleWare.getDriver(this.userData.driverUserProfile.id).then((d: any) => d);
  //
  //     // concat values and create name
  //     this.userData.driverUser =  this.userData.driverUserProfile.firstName + ' ' + this.userData.driverUserProfile.lastName;
  //
  //     // load driver profiles
  //     this.userData.driverProfiles = await this.apiMiddleWare.getDriverProfile(this.userData.driver.id).then((d: any) => d);
  //     // log driver out of tablets
  //     const driver_uuid = this.userData.driverProfiles[0].id;
  //     this.logDriverOutOfTablet(driver_uuid);
  //     this.driverLogsStore.dispatch(new SetDriverUuid(driver_uuid));
  //
  //     // grab duty status
  //     this.userData.driverProfiles.map(( x ) => {
  //       this.getDutyStatus(x.id).then(u => { this.userData.dutyStatus.push(u); } );
  //     });
  //     // grab company profile
  //     this.userData.driverProfiles.map(x => {
  //       this.apiMiddleWare.getCompanyProfile(
  //         x.companyId).then((data: any) => {
  //         const divisions = this.userData.driverProfiles.map(y => y.divisionId);
  //         this.apiMiddleWare.getDivisions(x.companyId, divisions).then(resp => {
  //           data.divisions = resp;
  //           this.userData.divisions.push(data);
  //           this.initalizePendo(this.userData);
  //         });
  //       });
  //     });
  //   });
  //
  // }
  setDutyStatus(id, value, type) {
    return this.apiMiddleWare.setDutyStatus(id, value, type).then((d: any) => {
      this.updateStatusValue(id, d);
      return d;
    });
  }
  updateStatusValue(x, d) {
    this.userData.dutyStatus.find(y => {
      if (y.profileId === x) {
        const update = {...y, created: d.created, dutyStatus: d.dutyStatus, specialDutyStatus: d.specialDutyStatus};
        this.userData.dutyStatus[this.userData.dutyStatus.indexOf(y)] = update;
        return true;
      }
    });
  }
  getAuthDomain() {
    return  this.env;
  }
  async checkIfAuth() {
    // if (this.auth.isAuthenticated()) {return true; }
    // return false;
    return this.auth.isAuthenticated$;
  }
  getProfilesList() {
    if (this.userData.driverProfiles) {
      return this.userData.driverProfiles.map(x => x);
    }
  }
  getUserDivisionsList() {
    const divisions = [];
    const profiles = {};
    const data = this.userData;
    if (this.userData.driverProfiles) {
      data.driverProfiles.map(x => {
        profiles[x.companyId] ? profiles[x.companyId].push(x.divisionId) : profiles[x.companyId] = [x.divisionId];
      });
      Object.keys(profiles).map(function(key, index) {
        data.divisions.map(x => {
          if (x.id === key) {
            x.divisions.map(z => {
              if (divisions.indexOf(z) === -1 && profiles[key].indexOf(z.id) > -1 && divisions.length < data.driverProfiles.length) {
                divisions.push(z);
              }
            });
          }
        });
     });
    }
    return divisions;
  }

  getHourDelta(creationTime) {
    // takes creation time and provides delta in hr or min

  }

  async getDutyStatus(id) {
    let x: any;
    await this.apiMiddleWare.getDutyStatus(id).then((data: any) => {
       Promise.resolve(data).then(y => {
        x = y;
        if (!y.error) {
         x.profileId = id;
        } else {
          if (y.name === 'HttpErrorResponse' && y.status === 422 && y.error?.message?.includes('doesn\'t have a current Duty Status')) {
              AppComponentService.driverWithNoDS.next(!!y.error);
          }
        }});
    });
    return x;
  }


  async getPDFExport(id, email: string, name: string, startDay: string, endDay: string, showViolations: boolean = true) {
      return this.apiMiddleWare.getPDFExport(id, email, name, startDay, endDay, showViolations).then((d: any) => {
          return d;
      });
  }

  // async logDriverOutOfTablet(id: string) {
  //     this.apiMiddleWare.logDriverOutOfTablet(id);
  // }

  // private initalizePendo(user: AppModel ) {
  //     const accountName = user.divisions[0].name;
  //     const id = user.divisions[0].divisions[0].legacyAccountCode;
  //     // initalize pendo
  //     this.ngxPendoService.initialize({
  //         id: localStorage.getItem('sub'),
  //         role: 'user',
  //         name: this.userData.driverUser
  //     }, {
  //         id: id,
  //         name: accountName
  //     });
  // }
}
