import {Action} from '@ngrx/store';

export const GET_SYSVAR = '[Get] GET_SYSVAR';
export const GET_SYSVAR_SUCCESS = '[Response] GET_SYSVAR_SUCCESS';

export interface SysvarEndpointParams {
    driverProfileUuid: string;
    payload: SysvarEndpointPayload;
}

export interface SysvarEndpointPayload {
    sysvar_name: string;
    convert?: 'bool' | 'int' | 'float';
}

export interface SysvarEndpointResponse {
    code: string;
    data: any; // this can change depending on the value of SysvarEndpointPayload's convert value
}

export class GetSysvar implements Action {
    readonly type = GET_SYSVAR;
    constructor(public params: SysvarEndpointParams, public sysvarName: string) { }
}

export class GetSysvarSuccess implements Action {
    readonly type = GET_SYSVAR_SUCCESS;
    constructor(public payload: SysvarEndpointResponse, public sysvarName: string) { }
}

export type SysvarAction =
    | GetSysvar
    | GetSysvarSuccess;
