
export class AppModel  {
    driverUserProfile: any;
    driverUser: any;
    driver: any;
    driverProfiles: any;
    driverFullProfiles: any;
    headerName: any;
    dutyStatus: any;
    divisions: any;
    onData: any;
    onerror: any;
    oncomplete: any;
    public constructor(init?: Partial<AppModel>) {
        Object.assign(this, init);
    }
}
