export interface AuthConfig {
    clientID: string;
    domain: string;
    callbackURL: string;
    apiUrl: string;
    scope?: string;
  }

  const AUTH_CONFIG_DEV: AuthConfig = {
    clientID: 'Wg1W0gk5rg78hjbF9S8VrTvlRsB6Hgmk',
    domain: 'zonar-dev.auth0.com',
    callbackURL: window.location.origin + '/callback',
    apiUrl: 'http://apidev.zonarsystems.net/',
    scope: 'openid profile email',
  };
  const AUTH_CONFIG_QA: AuthConfig = {
    clientID: 'AwLTrdpZnF5q0taVVVlxMPeEPT1T4ksh',
    domain: 'qa-login.zonarsystems.net',
    callbackURL: window.location.origin + '/callback',
    apiUrl: 'http://apiqa.zonarsystems.net/'
  };
  const AUTH_CONFIG_PROD: AuthConfig = {
    clientID: 'Cq2l5QZ2Q6hkrwfkEIaR3oiEZktOqQO6',
    domain: 'zonar-login.auth0.com',
    callbackURL: window.location.origin + '/callback',
    apiUrl: 'https://api.zonarsystems.net'
  };
  export let AUTH_ACTIVE_CONFIG = function(env: string) {
    switch (env) {
      case 'dev':
        // console.log('Using Environment: ' + JSON.stringify(AUTH_CONFIG_DEV));
        return AUTH_CONFIG_DEV;
        break;
      case 'qa':
        // console.log('Using Environment: ' + JSON.stringify(AUTH_CONFIG_QA));
        return AUTH_CONFIG_QA;
      case 'prod':
        // console.log('Using Environment: ' + JSON.stringify(AUTH_CONFIG_PROD));
        return AUTH_CONFIG_PROD;
    }
  };
