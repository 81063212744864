import { Title } from '@angular/platform-browser';
import { Component, OnInit, OnChanges } from '@angular/core';
import { ContentContainerComponent } from './content-container/content-container.component';
// import { AuthService } from './auth-module/auth/auth.service';
import { ApiMiddlewareModule } from './api-middleware/api-middleware.module';
import {AuthService} from '@auth0/auth0-angular';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [AuthService],
})

export class AppComponent implements OnInit, OnChanges {
  title = 'Driver Dashboard';
  constructor(
    public auth: AuthService,
    private titleService: Title,
     ) {
    auth.isAuthenticated$.subscribe((x) => {
      console.log(x);
      // alert('in app component');
      if (!x) {
        // alert('before logged in');
        auth.loginWithRedirect();
      } else {
        // console.log('logged in!!');
        // alert('logged in a guess???');
      }
    })

  }
  ngOnInit() {
    this.titleService.setTitle( 'Driver Dashboard' );
   }
  ngOnChanges() { }
}
