<app-header
  ></app-header>
<div class='tos'>
  <div>HELLO WORLD HERE TOS</div>
    <div class='tos-container'>
            <div class="clearfix" itemprop="articleBody">
                    <div class="vc_row wpb_row vc_row-fluid"><div class="wpb_column vc_column_container vc_col-sm-12"><div class="vc_column-inner "><div class="wpb_wrapper">
              <div class="wpb_text_column wpb_content_element ">
                  <div class="wpb_wrapper">
                      <h1>Driver Dashboard Website<sup></sup> Terms of Service</h1>
          <p class="lead">Welcome to the Zonar® Driver Dashboard website (<strong>“Website”</strong>) operated by Zonar Systems, Inc. (hereinafter referred to as <strong>“we, us, and/or our”). BY ACCESSING THE WEBSITE, SETTING UP A USER ACCOUNT, ACCESSING AND USING THE DRIVER DASHBOARD, AND/OR CLICKING THE “ACCEPT” BUTTON YOU AGREE TO BE BOUND BY THESE TERMS OF SERVICE AND OUR PRIVACY POLICY.</strong></p>
          <div class="phead">1. DRIVER DASHBOARD OVERVIEW.</div>
          <p>a. <span style="text-decoration: underline;">Overview.</span> The Website is intended primarily for Drivers, Operators and non-driving operators who are entitled to maintain their hours of service and access other information of interest to a driver. The website allows drivers to update their duty status, view/export/edit their record of duty status and also view their asset and profile information.</p>
          <p>b. <span style="text-decoration: underline;">Account Set-Up.</span> In order to use the Website, You register and provide Zonar with certain information, which may include personal information such as name, Commercial Driver’s License number, employer account information, and/or email address. Zonar collects and uses this information in order to provide the Website and in accordance with our Privacy Policy, the current version of which is available at <a href="/privacy/driver-dashboard/">https://www.zonarsystems.com/privacy/driver-dashboard/</a>. Registration is only available to persons over 18 years old. By using the registering for, accessing, or using the Website, You represent and warrant that: (a) all required registration information You submit is truthful and accurate; (b) You are authorized by the view the information contained on the Website, (c) You are accessing the Website for a legitimate purpose; and (d) You will only use the Website and Service as permitted by these Terms. Zonar cannot and will not be liable for any loss or damage arising from Your use of the Website, including any arising from Your failure to comply with the above requirements.</p>
          <p>c. <span style="text-decoration: underline;">Unauthorized Use.</span> If You choose to create an account, You will be a <strong>“Registered User.”</strong> As such, You are responsible for maintaining the confidentiality of Your user name and password. You are also responsible for all activities conducted through Your account, whether by You or someone else. You must immediately notify us of any unauthorized use of Your Account.</p>
          <p>d. <span style="text-decoration: underline;">Data Retention.</span> Zonar will retain all data available in the Website for 6 months.</p>
          <div class="phead">2. LICENSE AND OWNERSHIP OF CONTENT.</div>
          <p>a. <span style="text-decoration: underline;">Your Use.</span> We grant You a limited, non-exclusive, non-transferable, revocable limited license to access and use the Website as expressly permitted herein. We reserve all rights not expressly granted herein.</p>
          <p>b. <span style="text-decoration: underline;">Privacy.</span> Information about our Privacy Policy and how we use information we collect from You and about Your use of the Website is available <a href="/privacy/driver-dashboard/">here</a>. However, keep in mind that when You post Content or comments, Your user name will be displayed and the information and Content You submit to the Website is not private and can be viewed by any visitor the Website. You have no reasonable expectation of privacy when using the Website so do not submit Content or comments or information about yourself You do not want others to see.</p>
          <div class="phead">3. OUR PROPRIETARY RIGHTS.</div>
          <p>The Website and all logos, trademarks, buttons icons, images, pictures, graphics, content used in connection with the Website (collectively referred to as “Our Content”) are protected under U.S. intellectual property laws, and are exclusively owned and/or controlled by us. <strong>UNAUTHORIZED USE, MODIFICATION, REPUBLISHING, TRANSMITTING, DISTRIBUTING OR DUPLICATING OR ANY OTHER MISUSE OF OUR CONTENT IS PROHIBITED.</strong></p>
          <div class="phead">4. OUR PROPRIETARY RIGHTS.</div>
          <p>a. <span style="text-decoration: underline;">Limited Warranty.</span> Zonar will use reasonable efforts to make the Website available 24/7, exclusive of downtime necessary to improve, modify, maintain, upgrade or change the Website and associated services. Zonar warrants that the substantially in accordance with this Agreement and as described in any relevant documentation, which is subject to change. This limited warranty is given personally to You, and may not be transferred to any other person.</p>
          <p>b. <span style="text-decoration: underline;">Disclaimer.</span> YOU UNDERSTAND AND AGREE THAT THE WEBSITE IS PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS. WE AND/OR OUR RESPECTIVE SUPPLIERS MAKE NO REPRESENTATIONS ABOUT THE AVAILABILITY OF THE WEBSITE OR THE CONTENT YOU OR OTHER USERS SUBMIT. YOU ARE SOLELY RESPONSIBLE FOR YOUR INTERACTIONS WITH OTHER USERS. WE DISCLAIM TO THE FULLEST EXTENT PERMISSIBLE BY LAW, AND YOU WAIVE, ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS, IMPLIED, OR STATUTORY, INCLUDING WITHOUT LIMITATION THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT. FURTHER, TO THE EXTENT PERMITTED UNDER APPLICABLE LAWS, YOU HEREBY RELEASE US FROM ANY AND ALL CLAIMS OR LIABILITY RELATED TO ANY PRODUCT OR SERVICE OFFER BY OTHER USERS, ANY ACTION OR INACTION BY OTHER USERS, INCLUDING OTHER USER’S FAILURE TO COMPLY WITH THE TERMS OF SERVICES. THE FUNCTIONS AND FEATURES OF THE WEBSITE ARE NOT WARRANTED TO BE UNINTERRUPTED, TIMELY, SECURE OR ERROR-FREE. WE DISCLAIM ANY RESPONSIBILITY FOR THE DELETION, FAILURE TO STORE, MISDELIVERY, OR UNTIMELY DELIVERY OF ANY CONTENT. YOU ASSUME THE ENTIRE RISK OF LOSS OF CONTENT AND/OR DAMAGE DUE TO YOUR USE OF THE WEBSITE.</p>
          <div class="phead">5. LIMITATION OF LIABILITY.</div>
          <p>YOUR USE OF THE WEBSITE IS ENTIRELY AT YOUR SOLE RISK. NEITHER WE NOR OUR, OWNERS, DIRECTORS, OFFICERS, EMPLOYEES, LICENSORS, AND/OR AGENTS SHALL NOT BE LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL, CONSEQUENTIAL, SPECIAL, EXEMPLARY OR PUNITIVE DAMAGES OR LOSSES (INCLUDING WITHOUT LIMITATION LOSS OF PROFITS, GOODWILL, USE, DATA OR OTHER INTANGIBLE LOSSES), WHETHER BASED IN CONTRACT, TORT, STRICT LIABILITY, OR OTHERWISE, WHICH YOU MAY INCUR IN CONNECTION WITH THE USE OF, OR INABILITY TO USE, THE WEBSITE, EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES OR LOSS.</p>
          <div class="phead">6. INDEMNIFICATION.</div>
          <p>You shall indemnify, defend and hold harmless us and our officers, directors, employees, shareholders, and agents for any and all loss cost, disputes, demands claims and liabilities (including reasonable attorneys’ fees) arising out of or incurred due to: (i) Your breach of these Terms of Service; (ii) Your use or misuse of the Website; (iii) Your violation of any law or the rights of any third party; and/or (iv) Your interaction with other Website users.</p>
          <div class="phead">7. INDEMNIFICATION.</div>
          <p>You may terminate Your participation at any time by discontinuing use of the Website and Content. At that time we may delete information You have submitted via the Website. If You have a dispute with us relating to the Website or Content, immediately cease all use of the Website/Content. Ceasing all use of the Website and Content is Your only remedy with respect to any such dispute that You may have with us. The following Sections shall survive termination of these Terms of Service or Your use of the Website: 2(a), 4, 5, 6, 7, 8, 9, 14 and 15.</p>
          <div class="phead">8. CHANGES TO WEBSITE AND TERMS OF SERVICE.</div>
          <p>From time to time, we may modify, suspend or discontinue any feature associated with Your access and use of the Website and/or Content. We shall not be liable to You for any modification, suspension or discontinuance (in part or wholly) of the Website and/or features associated with the availability or use of Content. We may establish additional policies and practices concerning use of the Website and Content made available through the Website. Accordingly, We reserve the right to change these Terms of Service (in part or wholly) at any time, with or without prior notice. We will notify You of any such changes by posting updated Terms of Service at <a href="/terms-of-use/driver-dashboard/">https://www.zonarsystems.com/terms-of-use/driver-dashboard/</a> and/or by notifying You via the email address linked to Your Account.</p>
          <div class="phead">9. THIRD PARTY LINKS.</div>
          <p>Some Content may incorporate links to third party websites. Some third party websites may collect data or solicit personal information from You. We neither own nor control such third party websites and is not responsible for their content or actions. Please read the terms and conditions and privacy policies of any third party website that may be linked to the Website.</p>
          <div class="phead">10. PRIVACY.</div>
          <p>We collect and use information about You and Your use of the Website and Content for the purpose of making the Website available to You and for developing and improving the Website. Please review our <a href="/privacy/driver-dashboard/">Privacy Policy</a> for more information.</p>
          <div class="phead">11. COMMUNICATIONS.</div>
          <p>By agreeing to these Terms of Service, You consent to receive communications sent from or on our behalf regarding the Website, Content and/or Your rights and obligations under these Terms of Service.</p>
          <div class="phead">12. CHOICE OF LAW AND VENUE.</div>
          <p>These Terms of Service, the Privacy Policy, Your access and use of the Website and Content, and the relationship between You and us are governed by the laws of the State of Washington, without giving effect to its conflict of law provisions.</p>
          <div class="phead">13. DISPUTE RESOLUTION.</div>
          <p>You expressly acknowledge and agree that the sole and exclusive venue for resolving any controversy or claim arising out of or relating to these Terms of Service, or otherwise relating to any rights in, access to or use of the Website and/or Content shall be binding arbitration, under the American Arbitration Association (“AAA”) commercial arbitration rules, then in effect. You also agree that the AAA optional rules for emergency measures of protection shall also apply to the proceedings. Judgment on the award rendered by the arbitrators may be entered in any court having jurisdiction thereof. In the event that this provision is held invalid or unenforceable, the remaining portions of this section shall remain in full force and effect. <strong>YOU AGREE NOT TO BRING OR PARTICIPATE IN A CLASS OR REPRESENTATIVE ACTION, PRIVATE ATTORNEY GENERAL ACTION OR COLLECTIVE ARBITRATION, EVEN IF AAA’S RULES ALLOW FOR SUCH.</strong></p>
          <div class="phead">14. INTELLECTUAL PROPERTY INFRINGEMENT CLAIMS.</div>
          <p>If You believe that Your work or the work of another is being used in a manner that constitutes copyright infringement, please notify us at <a href="mailto:CustomerCare@Zonarsystems.com">CustomerCare@Zonarsystems.com</a>. Your notification must include the following:</p>
          <ul>
          <li>an electronic or physical signature of the person authorized to act on behalf of the owner of the copyright or other intellectual property interest.</li>
          <li>a description of the copyrighted work or other intellectual property that You claim has been infringed.</li>
          <li>a description of where the material that You claim is infringing is located on the Website;</li>
          <li>Your address, telephone number, and email address.</li>
          <li>a statement by You that You have a good faith belief that the disputed use is not authorized by the copyright owner, its agent, or the law.</li>
          <li>a statement by You, made under penalty of perjury, that the above information in Your notice is accurate and that You are the copyright or intellectual property owner or authorized to act on the copyright or intellectual property owner’s behalf.</li>
          </ul>
          <div class="phead">15. OUR CONTACT INFORMATION.</div>
          <p>If You have any questions or concerns, please contact us at: <a href="mailto:CustomerCare@Zonarsystems.com">CustomerCare@Zonarsystems.com</a>; Zonar Systems, Inc., 18200 Cascade Ave. S., Seattle, WA 98188, Attn: Legal Dept.; or (877) 843-3847.</p>
          <p>Last Updated: March 20, 2018.</p>

                  </div>
              </div>
          </div></div></div></div>
                  </div>

    </div>
    <div class='button-container'>
            <button mat-raised-button color="accent" class='agree' (click)="redirect()">ACCEPT</button>
    </div>

</div>
