import { Router } from '@angular/router';
import { Injectable, NgZone } from '@angular/core';
import * as store from 'store';
import {AuthService} from '@auth0/auth0-angular';
// import {AuthService} from './auth-module/auth/auth.service';

const MINUTES_UNITL_AUTO_LOGOUT = 5; // in Minutes
// const MINUTES_UNITL_AUTO_LOGOUT = 60; // in Minutes
const CHECK_INTERVALL = 1000; // in ms
const STORE_KEY = 'lastAction';

@Injectable()
export class AutoLogoutService {

    private isTimeout = false;

    constructor(
        private auth: AuthService,
        private router: Router,
        private ngZone: NgZone
    ) {
        this.initListener();
        this.initInterval();
        this.reset();
        this.check();
    }

    get lastAction() {
        return parseInt(store.get(STORE_KEY));
    }
    set lastAction(value) {
        store.set(STORE_KEY, value);
    }

    initListener() {
        this.ngZone.runOutsideAngular(() => {
            document.body.addEventListener('click', () => this.reset());
            document.body.addEventListener('touchstart', () => this.reset());
        });
    }

    initInterval() {
        this.ngZone.runOutsideAngular(() => {
            setInterval(() => {
                this.check();
            }, CHECK_INTERVALL);
        });
    }

    reset() {
        this.lastAction = Date.now();
    }

    check() {
        if (this.lastAction) {
            const now = Date.now();
            const timeleft = this.lastAction + MINUTES_UNITL_AUTO_LOGOUT * 60 * 1000;
            const diff = timeleft - now;
            this.isTimeout = diff < 0;
        }

        // this.ngZone.run(() => {
        //     if (this.isTimeout) {
        //         console.log(`Logging driver out pls ${MINUTES_UNITL_AUTO_LOGOUT} minutes of inactivity.`);
        //         localStorage.removeItem('lastAction');
        //         this.isTimeout = false;
        //         this.auth.logout();
        //     }
        // });
    }
}
