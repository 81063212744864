import { GET_SYSVAR, GetSysvar, SysvarAction, GET_SYSVAR_SUCCESS } from './app.actions';

export const SysvarReducer = setReducer;

export interface SysvarState {
    sysvars: any;
}

export const initialState: SysvarState = {
    sysvars: {}
};

export function setReducer(state = initialState, action: SysvarAction): SysvarState {
    switch (action.type) {
        case GET_SYSVAR:
            return state;

        case GET_SYSVAR_SUCCESS:
            const newSysvarObject = {};
            newSysvarObject[action.sysvarName] = action.payload.data === 'True' ? true : false;
            return {
                ...state,
                sysvars: newSysvarObject
            };
        default:
            return state;
    }
}
