import { Injectable } from '@angular/core';
import {AuthService} from '@auth0/auth0-angular';
import {ApiMiddlewareService} from './api-middleware/api-middleware.service';
import {map, switchMap} from 'rxjs/operators';
import {AppModel} from './app.model';
import {NgxPendoService} from 'ngx-pendo';

@Injectable({
  providedIn: 'root'
})
export class UserInfoService {
  driverProfileUuid;
  companyId;
  driverInformation = {
    userName: '',
    company: '',
    userId: '',
    divisions: [],
    currentDivision: '',
    authStatus: false,
  }

  constructor(
    private authService: AuthService,
    private apiService: ApiMiddlewareService,
    protected ngxPendoService: NgxPendoService
  ) {

    authService.isAuthenticated$.subscribe((authStatus) => {
      this.driverInformation.authStatus = authStatus;
    });

    authService.user$.pipe(
      map((user) => {
        if (user) {
          console.log(user);
          localStorage.setItem('sub', user.sub);
          return user.sub;
        }
      }),
      switchMap((user) => this.apiService.getUser(user)),
      map((driver) => {
        console.log('user', driver);
        this.driverInformation.userName = `${driver[0].firstName} ${driver[0].lastName}`;
        return driver[0]
      }),
      switchMap((driver) => this.apiService.getDriver(driver.id)),
      map((result) => {
        console.log(result, 'driver');
        return result;
      }),
      switchMap((r) => this.apiService.getDriverProfile(r.id)),
      map((a: any) => {
        console.log(a, 'driver profile');
        this.driverInformation.userId = a[0].id;
        this.companyId = a[0].companyId;
        this.driverInformation.divisions.push(a[0].divisionId);
        return a[0];
      }),
      switchMap(() => this.apiService.getCompanyProfile(this.companyId)),
      map((company) => {
        console.log('company', company);
        this.driverInformation.company = company.name;
        return;
      }),
      switchMap(() => this.apiService.getDivisions(this.companyId, this.driverInformation.divisions)),
      map((divisons) => {
        console.log('divisions', divisons);
        this.driverInformation.currentDivision = divisons[0].name;
        console.log(this.driverInformation.currentDivision)
        this.initalizePendo(divisons[0]);
        return;
      }),
      switchMap(() => this.apiService.logDriverOutOfTablet(this.driverInformation.userId)),
      switchMap(() => this.apiService.getDutyStatus(this.driverInformation.userId)),
      map((dutyStatus) => {
        //can return an error if it's an error do something
        console.log(dutyStatus, 'duty status');
        return;
      }),
    ).subscribe();
  }


  private initalizePendo(division ) {
    const accountName = division.name;
    const id = division.legacyAccountCode;
    // initalize pendo
    this.ngxPendoService.initialize({
      id: localStorage.getItem('sub'),
      role: 'user',
      name: this.driverInformation.userId
    }, {
      id: id,
      name: accountName
    });
  }
}
