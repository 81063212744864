import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import * as auth0 from 'auth0-js';
import env from '../../../../json/env.json';
import { AUTH_ACTIVE_CONFIG } from './auth0-variables';
import { NgxPendoService } from 'ngx-pendo';

@Injectable()
export class AuthService {
  env = this.getEnvironment();
  auth0 = new auth0.WebAuth({
    clientID: AUTH_ACTIVE_CONFIG(env['env']).clientID,
    domain: AUTH_ACTIVE_CONFIG(env['env']).domain,
    responseType: 'token id_token',
    audience: AUTH_ACTIVE_CONFIG(env['env']).apiUrl,
    redirectUri: AUTH_ACTIVE_CONFIG(env['env']).callbackURL,
    scope: 'openid profile email'
  });

  constructor(public router: Router) {}

  public login(): void {
    this.auth0.authorize();
  }
  public getEnvironment() {
    return env;
  }
  public handleAuthentication(): void {
    this.auth0.parseHash((err, authResult) => {
      if (authResult && authResult.accessToken && authResult.idToken) {
        window.location.hash = '';
        this.setSession(authResult);
        this.router.navigate(['/']);
      } else {
        this.router.navigate(['/dashboard']);
        console.log(err);
      }
    });
  }

  private setSession(authResult): void {
    // Set the time that the Access Token will expire at
    const expiresAt = JSON.stringify((authResult.expiresIn * 1000) + new Date().getTime());
    localStorage.setItem('sub', authResult.idTokenPayload.sub);
    localStorage.setItem('email', authResult.idTokenPayload.sub.split('|')[2]);
    localStorage.setItem('access_token', authResult.accessToken);
    localStorage.setItem('id_token', authResult.idToken);
    localStorage.setItem('expires_at', expiresAt);
  }

  public logout(): void {
    if(localStorage.getItem('expires_at')) {
      this.auth0.logout();
      localStorage.removeItem('sub');
      localStorage.removeItem('email');
      localStorage.removeItem('access_token');
      localStorage.removeItem('id_token');
      localStorage.removeItem('expires_at');
    } else {
      this.login();
    }
  }

  public isAuthenticated(): boolean {
    // Check whether the current time is past the
    // Access Token's expiry time
    const expiresAt = JSON.parse(localStorage.getItem('expires_at'));
    return new Date().getTime() < expiresAt;
  }
}
