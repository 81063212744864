<div>HELLO WORLD HERE CONTENT CONTAINER</div>
<div class="fx-container">
  <app-header
     userName={{this.driverInfo.userName}}
     company={{this.driverInfo.company}}
     selectedDivision={{this.driverInfo.currentDivision}}
     logoUrl='../assets/layout/images/dashboard/logo.svg'
     isAuthorized={{this.driverInfo.authStatus}}
     [divisions]=this.divisions
     (selectedDivisionChange)='headerOnClick($event)'
    ></app-header>
  <div class="warning-banner">
    <span class="buffer-space"></span>
    <zui-hos-banner [showBanner]="false" [bannerText]="displayText | async" [icon]="'warning'"
                    [showClose]="false" class="warning-container"></zui-hos-banner>
  </div>
  <div class='content-container row' *ngIf="this.driverInfo.authStatus">
    <div class='content-header col-12 col-12-sm'>
        <app-title
          motorCarrierName={{this.driverInfo.company}}
          motorCarrierDot="Motor Carrier's DOT #"
        ></app-title>
        <div class="button-space">
            <div class="action-buttons">
                <app-certify-button
                      *ngIf="this.driverInfo.userId"
                      (toggleCertify)="toggleCertify($event)"
                      [isDisabled]="isInCertifyMode && isDriverWithNoDutyStatus"
              ></app-certify-button>
                <app-export-button
                      *ngIf="this.driverInfo.userId"
                      userName={{this.driverInfo.userName}}
                      driverUUID={{this.driverInfo.userId}}
                      [isDisabled]="isDriverWithNoDutyStatus"
                ></app-export-button>
            </div>
            <app-certify-logs-warning></app-certify-logs-warning>
        </div>
    </div>
    <div class='col-3 card col-4-md col-12-sm'>

      <!-- contentArray only ever has one item; why is an array being used? -->
        <app-content-card
          *ngFor='let item of contentArray; let i = index'
          [cardmodel]=item
          (outClick)="onClick($event)"
          key={{i}}
          type={{type}}
        ></app-content-card>
    </div>
<!--    <div class='col-9 col-8-md col-12-sm'>-->
<!--        <div *ngIf="!isInCertifyMode; else certify">-->
<!--            <app-driver-clocks-list></app-driver-clocks-list>-->
<!--            <app-driver-grid></app-driver-grid>-->
<!--            <app-driver-event-list></app-driver-event-list>-->
<!--        </div>-->

<!--        <ng-template #certify>-->
<!--            <app-certify-logs-control (toggleCertify)="toggleCertify($event)"></app-certify-logs-control>-->
<!--            <app-driver-grid [isInCertifyMode]=true></app-driver-grid>-->
<!--            <app-driver-event-list [isInCertifyMode]=true></app-driver-event-list>-->
<!--        </ng-template>-->
<!--    </div>-->
  </div>
</div>
<div class="footer-zui">
  <zui-footer></zui-footer>
</div>
