import { Component, OnInit } from '@angular/core';
// import { AuthService } from '../auth/auth.service';
import { Router } from '@angular/router';
import {AuthService} from '@auth0/auth0-angular';

@Component({
  selector: 'app-callback',
  templateUrl: './callback.component.html',
  styleUrls: ['./callback.component.css']
})
export class CallbackComponent implements OnInit {
  profileJson

  constructor(
    public router: Router,
    public auth: AuthService
  ) {
    console.log('hello world');
    this.auth.user$.subscribe((userProfile) => {
      // alert(userProfile);
      this.router.navigate(['/dashboard']);
    });
  }

  async ngOnInit() {
    this.auth.user$.subscribe(
      (profile) => (this.profileJson = JSON.stringify(profile, null, 2))
    );

    // if (await this.auth.isAuthenticated$) {
    //   console.log('here');
    //   alert('here before navigation');
    //   this.router.navigate(['/dashboard']);
    //   // this.auth.handleRedirectCallback(window.location.origin);
    // }
  }

}
