import {createFeatureSelector, createSelector} from '@ngrx/store';
import { SysvarState} from './app.reducer';

export const selectSysvarState = createFeatureSelector<SysvarState>('sysvars');

export const getSysvars = (state: SysvarState) => {
    if (state) { return state.sysvars; }
};

export const selectSysvars = createSelector(
    selectSysvarState, getSysvars
);
